export type AskResponse = {
    answer: string;
    citations: Citation[];
    error?: string;
    question: string;
    langfuseTraceId?: string;
    answerId: string;
    isLastAnswer: boolean;
};

export type Citation = {
    content: string;
    id: string;
    title: string | null;
    filepath: string | null;
    url: string | null;
    metadata: string | null;
    chunk_id: string | null;
    reindex_id: string | null;
    org_name: string | null;
    org_id: string;
}

export type ToolMessageContent = {
    citations: Citation[];
    intent: string;
}

export type ChatMessage = {
    id: string;
    role: string;
    content: string;
    end_turn?: boolean;
    langfuseTraceId?: string;
    date: string;
};

export type Conversation = {
    id: string;
    title: string;
    messages: ChatMessage[];
    date: string;
}

export enum ChatCompletionType {
    ChatCompletion = "chat.completion",
    ChatCompletionChunk = "chat.completion.chunk"
}

export type ChatResponseChoice = {
    messages: ChatMessage[];
}

export type ChatResponse = {
    id: string;
    model: string;
    created: number;
    object: ChatCompletionType;
    choices: ChatResponseChoice[];
    history_metadata: {
        conversation_id: string;
        title: string;
        date: string;
    }
    langfuse_trace_id: string;
    continue_generating: boolean;
    error?: any;
}

export type Language = 'en-ca' | 'fr-ca';

export type ConversationRequest = {
    messages: ChatMessage[];
    lang: Language;
    province: string;
    index: string;
    region: string;
    continue_generating: boolean;
};

export type UserInfo = {
    access_token: string;
    expires_on: string;
    id_token: string;
    provider_name: string;
    user_claims: any[];
    user_id: string;
};

export type ApplicationInfo = {
    allowAnonymousUser: boolean;
    role: string;
};

export enum CosmosDBStatus {
    NotConfigured = "CosmosDB is not configured",
    NotWorking = "CosmosDB is not working",
    Working = "CosmosDB is configured and working",
}

export type CosmosDBHealth = {
    cosmosDB: boolean,
    status: string
}

export enum ChatHistoryLoadingState {
    Loading = "loading",
    Success = "success",
    Fail = "fail",
    NotStarted = "notStarted"
}

export type ErrorMessage = {
    title: string,
    subtitle: string
}

export type FeedBack = "LIKED" | "DISLIKED" | "DEFAULT" | undefined;

export enum FeedBackStatus {
    LIKED = "LIKED",
    DISLIKED = "DISLIKED"
}

export type FrontendSettings = {
    isAnonymousUserAllowed?: boolean,
    APPINSIGHTS_INSTRUMENTATIONKEY: string,
    VITE_APP_NAME: string,
    VITE_APP_SITE_TITLE_URL: string,
    AZURE_STORAGE_BASEURL: string,
    LANGFUSE_PUBLIC_KEY: string,
    LANGFUSE_HOST: string,
    SUPPORT_MULTI_LANG: boolean,
    SUPPORT_MULTI_CONTEXT: boolean,
    CHAT_SETTINGS_ENABLED: boolean,
    LOCALSTORAGE_KEY: string,
}