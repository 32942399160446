import ChatContextButton from './ChatContextButton';
import { useChatContext } from '../../state/ChatContextProvider';
import { Stack } from '@fluentui/react';
import { useTranslation } from '../../state/LanguageProvider';

type contextType = {
    key: string;
    text: string;
}

type contextGroupType = [{
    [key:string]: contextType;
}]

const ChatContextSelection = () => {
    const { chatContextKey, setChatContextKey, availableChatContexts } = useChatContext();
    const { getTranslation } = useTranslation();

    const chatContextsLabel = computeChatButtonLabel(availableChatContexts)
    function computeChatButtonLabel(availableChatContexts: contextGroupType){
        let chatBtnLabel: any
        availableChatContexts.map(function(items) {
            Object.entries(items).map(([key, text]) => (
                key.toLowerCase() === "contexts" ? chatBtnLabel = text : 0
            ))
        })  
        return chatBtnLabel
    }

    return (
        <div>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                { Object.entries(chatContextsLabel).map(([key, text]) => (
                    <ChatContextButton
                        label={`${getTranslation('help_me_with')} ${text}`}
                        isActive={chatContextKey  === key}
                        onClick={() => setChatContextKey(localStorage.getItem('context') || key)}
                    />
                ))}
            </Stack>
        </div>
    );
};

export default ChatContextSelection;
