import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { getChatContexts, Language } from '../api';
import { useLanguage } from './LanguageProvider';
import { getObjectFromLocalStorage } from '../utils/localStorageUtils';

interface ChatContextProps {
    chatContextKey: string;  
    contextExists: boolean;
    setChatContextKey: (context: string) => void;
    chatRegionKey: string; 
    regionExists: boolean;
    setChatRegionKey: (context: string) => void;
    availableChatContexts: contextGroupType;
    botName: string;
    setBotName: (context: string) => void;
    provinceKey: string;
    setProvinceKey: (province: string) => void;  
    provinceExists: boolean
}

type botType = {
    botName: string;
    value: string;
}

type contextType = {
    key: string;
    text: string;
}

type contextGroupType = [{
    [key:string]: contextType;
}]

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const ChatContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [chatContextKey, setChatContextKey] = useState<string>(''); // Set default to '' if no context
    const [chatRegionKey, setChatRegionKey] = useState<string>(''); // Set default to '' if no context
    const [provinceKey, setProvinceKey] = useState<string>(''); // Set default to '' if no context
    const [availableChatContexts, setAvailableChatContexts] = useState<contextGroupType>([{}]);
    const [botName, setBotName] = useState<string>(''); 
    const [contextExists, setContextExists] = useState<boolean>(false);
    const [regionExists, setRegionExists] = useState<boolean>(false);
    const [provinceExists, setProvinceExists] = useState<boolean>(false);
    const { language } = useLanguage();

    const fetchContext = async (lang: Language) => {
        try {
            const contexts: contextGroupType = await getChatContexts(lang);
            let botSettings: string = ''; 
            if (contexts && contexts.length > 0) {
                const remItem = contexts.pop() as unknown as botType;
                botSettings  = remItem.botName;
                setBotName(remItem.botName);
                setAvailableChatContexts(contexts);
                const storedObject = getObjectFromLocalStorage(remItem.botName); 
                contexts.forEach((items) => {
                    Object.entries(items).forEach(([key, value]) => {
                        if (key.toLowerCase() === "contexts") { 
                            setChatContextKey(storedObject?.[`context_${lang}`] ?? Object.keys(value)[0]);
                            setContextExists(true);
                        } else if (key.toLowerCase() === "region") {
                            setChatRegionKey(storedObject?.region || Object.keys(value)[1]);
                            setRegionExists(true);
                        } else if (key.toLowerCase() === "provinces") {
                            // Province default is '' 
                            setProvinceKey(storedObject?.province ?? '');
                            setProvinceExists(true);
                        }
                    });
                });
            }
        } catch (error) {
            console.error('Error fetching context:', error);
        }
    };

    useEffect(() => {
        fetchContext(language);
    }, [language]);

    return (
        <ChatContext.Provider value={{ 
            availableChatContexts, 
            botName, setBotName,
            chatContextKey, setChatContextKey, contextExists, 
            chatRegionKey, setChatRegionKey, regionExists, 
            provinceKey, setProvinceKey, provinceExists }}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = (): ChatContextProps => {
    const context = useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChatContext must be used within a ChatContextProvider');
    }
    return context;
};
